import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components}>
    <h1 {...{
      "id": "404"
    }}>{`404`}</h1>
    <p>{`Page not found.  `}<a parentName="p" {...{
        "href": "/"
      }}>{`Return Home`}</a></p>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      